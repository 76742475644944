<template>
  <router-view v-slot="{ Component, route }">
    <ReloadPWA />
    <ReloadIntercom />
    <LoadHotjar />
    <transition
      :name="route.meta.transition || ''"
      mode="out-in"
    >
      <div :key="route.name">
        <component
          :is="Component"
          :key="accountStore.reload"
        />
      </div>
    </transition>
  </router-view>
</template>

<script lang="ts">
import { defineComponent } from "vue"

import ReloadPWA from "@/components/ReloadPWA.vue"
import ReloadIntercom from "./components/ReloadIntercom.vue"
import LoadHotjar from "@/components/LoadHotjar.vue"

import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"
import { useAuthenticationStore } from "@/stores/authentication"
import { useEstablishmentStore } from "@/stores/establishment"
import { useRoute } from "vue-router"

export default defineComponent({
  name: "App",

  components: { LoadHotjar, ReloadPWA, ReloadIntercom },

  computed: {
    ...mapStores(useAccountStore, useAuthenticationStore, useEstablishmentStore),
  },

  created() {
    this.initApp()
  },

  methods: {
    initApp() {
      if (this.authenticationStore.user && this.authenticationStore.user!.email != undefined) {
        this.accountStore.getEstablishments(() => {
          const currentEstablishment = this.establishmentStore.currentEstablishment

          if (currentEstablishment) {
            // needed to refresh product plan if updated on nova
            this.establishmentStore.getCurrentEstablishment(currentEstablishment.id)
          }

          const language = this.authenticationStore.user?.language

          if (language) {
            this.$i18n.locale = this.authenticationStore.user.language
            this.$traduction.changeLang(language)
          }

          this.accountStore.establishmentLoaded = true
        }, false)
      }
    },
  },
})
</script>
